<template>
    <div class="card-body" >
        <div class="card-notifications" >
            <div>
                <p>
<!--                    News item posted in the Agency for Health Care Administration titled-->
                    {{item.description}}
                </p>
                <a>
<!--                    Senate confirms 16 agency, department heads including Joseph Ladapo-->
                {{item.name}}
                </a>

            </div>
            <div class="date-card">
<!--                on 5/4/23@8:00am -->
                {{item.date}}}
            </div>

        </div>
        <div >
            <button> clear</button>
        </div>
    </div>

</template>

<script>
export default {
    name: 'Notification-card',
    props: [
        'item'
    ],
    setup() {
        return {
            props
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/mixins/_bemify.scss";

.card-notifications {
    width: 80%;
    height: 200px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-body {
    display: flex;
    flex-direction: row;
}
.date-card {

}

</style>
