<template>
    <!-- Page: pages/my-notifications -->
    <br/>

    <breadcrumbs :items="path"/>

    <br/>

    <div class="header-notification">
        <div class="container dfw">
            <div>
                <h1>My Notifications</h1>
            </div>
           <div class="link">
               <a href="/my-notification-settings" ><span> settings</span></a>
           </div>
<!--            <app-button icon="fagears" @click="$router.push('/my-notification-settings')" > settings</app-button>-->

            <!--        <user-tools/>-->
        </div>
        <div class="description-head">
            update to items you`ve choisen to follow will show up in this notification list. Filter by type using the filter set below.
        </div>
    </div>


    <general-container>
        <div class="row">
<!--            left side options-->
            <div class="filter-left">
                <div>
                    <h5> Filter by Content type</h5>

                    <div class="search-filter-group left-list">
                        <div class="content-block__content">
                            <ul>
                                <li v-for="contentType in ContentTypes">
                                    <div class="field">
                                        <input
                                            class="switch is-rounded"
                                            type="checkbox">
                                        <label :for="'contentType-' + contentType.type"
                                               class="agency-filter-label">{{ contentType.title }}</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div>
                    <h5> Filter Agencies</h5>

                    <div class="search-filter-group left-list">
                        <div class="content-block__content">
                            <ul>
                                <li v-for="agency in agencies">
                                    <div class="field">
                                        <input :id="'agency-' + agency.locationId"
                                               class="switch is-rounded"
                                               v-on:click="clickFilter($event)" type="checkbox"
                                               :value="agency.locationId">
                                        <label :for="'agency-' + agency.locationId"
                                               class="agency-filter-label">{{ agency.name }}</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

<!--            end left side options-->
            <div  class="filter-body">
<!--                body notification-->
                <div class="filter-head">
                    <div>
                        <div class="card date-range-card">
                            <div class="row step" >
                                <h3><label class="label">Filter by Date Range:  </label></h3>
                                <div class="date-set">
                                    <div class="row ">
                                        <div class="row">
                                            <span class="control-title">From:</span>
                                            <div class="row">
                                                <div class="control-icon">
                                                    <font-awesome-icon :icon="faCalendarDay" aria-label="Calendar icon"></font-awesome-icon>
                                                </div>
                                                <div class="control-input">
                                                    <Datepicker
                                                        v-model="dateStartStart"
                                                        :clearable="true"
                                                        :inputFormat="'MM/dd/yyyy'"
                                                        :typeable="true"
                                                        :placeholder="'mm/dd/yyyy'"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <span class="control-title">to:</span>
                                            <div class="control">
                                                <div class="control-icon">
                                                    <font-awesome-icon :icon="faCalendarDay" aria-label="Calendar icon"></font-awesome-icon>
                                                </div>
                                                <div class="control-input">
                                                    <Datepicker
                                                        v-model="dateStartEnd"
                                                        :clearable="true"
                                                        :inputFormat="'MM/dd/yyyy'"
                                                        :typeable="true"
                                                        :placeholder="'mm/dd/yyyy'"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="button-row">
                                    <div>
                                        <app-button>New</app-button>
                                        <app-button :is-budget="true">Cleared</app-button>
                                    </div>
                                    <div>
                                        <app-button>Clear All</app-button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
<!--                end body filter-->
                <div class="list-notification">
                    <h3> Card list</h3>

                    <template  v-for="item in listNotification">
                        <notification-card
                            :type="item.type"
                            :item="item"
                        />
                    </template>
<!--                    <notification-card item="info"/>-->

                </div>
                <pagination />
            </div>




        </div>

        <preloader v-if="notificationsLoading"></preloader>



    </general-container>

</template>

<script>
import UserTools from "./user-tools.vue";
import Breadcrumbs from "./breadcrumbs.vue";
import GeneralContainer from "./general-container.vue";
import TagVendor from "./tag--vendor.vue";
import TagContract from "./tag--contract.vue";
import TagRole from "./tag--role.vue";
import TagProcurement from "./tag--procurement.vue";
import TagBudget from "./tag--budget.vue";
import TagContact from "./tag--contact.vue";
import TagAgency from "./tag--agency.vue";
import Preloader from "./preloader.vue";
import {ref} from "vue";
import AppButton from "./app-button.vue";
import Datepicker from "vue3-datepicker";
import NotificationCard from "./notification-card.vue";
import { faCalendarDay } from '@fortawesome/pro-duotone-svg-icons';
import Pagination from "./pagination.vue";
export default {
    data () {
      return {
          faCalendarDay: faCalendarDay,
      }
    },
    methods: {
    },
    components: {
        Pagination,
        NotificationCard,
        Datepicker,
        AppButton,
        Preloader,
        TagAgency, TagContact, TagBudget, TagProcurement, TagRole, TagContract, TagVendor,
        GeneralContainer,
        Breadcrumbs,
        UserTools,
    },
    mounted() {

    },
    computed: {
        agencies: function () {
            return this.$store.getters.agencies;
        },
    },
    setup() {
        const info = {
            description: 'some text is heare',
            name: 'Kris Brotter',
            date: '12-02-2020'
        }
        const ContentTypes = [
            { title: 'Agencies', },
            { title: 'Budget Entities'},
            { title: 'Contacts' },
            { title: 'Contracts'},
            { title: 'Positions'},
            { title: 'Bids'},
            { title: 'Vendors'},
        ];
        const listNotification = [
            {type: ' agency',
            description: ' News item posted in the Agency for Health Care Administration titled',
                name:'Senate confirms 16 agency, department heads including Joseph Ladapo',
                date:'on 5/4/23@8:00am'
            }
        ]
        const notificationsLoading = ref (false)
        return {
            notificationsLoading,
            ContentTypes,
            path: [{ title: 'My Notifications' }],
            info,
            listNotification
        }
    }
}

</script>

<style lang="scss" scoped>

@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/mixins/_bemify.scss";

li {
    margin: 10px 0;
}
.header-notification {
    margin-bottom: 25px;
}
.step {
    padding: 10px;
}
.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.description-head {
    padding: 0 20px ;
}
.filter-left {
    width: 30%;
    background-color: white;
    padding: 15px;
}
.left-list {
    border: 1px solid $blue--med;
    margin: 10px 0;
    padding: 10px;
}
.filter-body {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0 10px;
}
.filter-head {
}
.link {
    padding: 15px;
}
.date-set {
    padding: 0 10px
}
.button-row {
    display: flex;
    flex-direction: row;
    padding: 10px 0 10px 240px;
    justify-content: space-around;
    //padding-left: 240px;
}
</style>
